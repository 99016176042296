import css from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import {
  ContentfulAsset,
  ContentfulImageHeadingAndShortText,
  MarkdownRemark,
} from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import presets from '../../styles/presets';
import { OpenSansParagraph } from '../common/typography';
import MarkdownRenderer from '../common/MarkdownRenderer';

interface Props {
  heading: ContentfulImageHeadingAndShortText;
  mobileVine: ContentfulAsset;
  desktopVine: ContentfulAsset;
  principlesParagraph: MarkdownRemark;
}

const sectionStyle = css`
  margin-top: 100px;
`;

const wrapperStyle = css`
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 440px;
`;

const VineWrapper = styled.div`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;

  .mobile-vine {
    width: 100%;
  }

  .desktop-vine {
    display: none;
    width: 100%;
  }

  ${presets.md} {
    margin-top: 0;

    .mobile-vine {
      display: none;
    }

    .desktop-vine {
      display: block;
    }
  }
`;

const paragraphWrapperStyle = css`
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 560px;
`;

const paragraphStyle = css`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  ${presets.md} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Principles: React.FC<Props> = ({
  heading,
  mobileVine,
  desktopVine,
  principlesParagraph,
}) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <TripleTitle
          labelText={heading.prefix}
          headingText={heading.heading}
          paragraphText={heading.shortText}
        />
      </ContentWrapper>
      <VineWrapper>
        <img
          className="mobile-vine"
          src={mobileVine.file.url}
          alt={mobileVine.description}
        />
        <img
          className="desktop-vine"
          src={desktopVine.file.url}
          alt={desktopVine.description}
        />
      </VineWrapper>
      <ContentWrapper css={paragraphWrapperStyle}>
        <OpenSansParagraph css={paragraphStyle}>
          <MarkdownRenderer {...principlesParagraph} />
        </OpenSansParagraph>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Principles;
