import css from '@emotion/css';
import React from 'react';
import { ContentfulImageHeadingAndShortText } from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import BackgroundImageMobile from '../../assets/images/science/challenge_background_mobile.svg';
import BackgroundImageDesktop from '../../assets/images/science/challenge_background_desktop.svg';
import presets from '../../styles/presets';

interface Props {
  heading: ContentfulImageHeadingAndShortText;
}

const sectionStyle = css`
  background: url(${BackgroundImageMobile});
  background-size: 100% 100%;
  background-position: 50% 0;
  background-repeat: no-repeat;

  padding-top: 120px;
  padding-bottom: 89px;

  ${presets.md} {
    background: url(${BackgroundImageDesktop});
    background-repeat: no-repeat;
    background-size: 1600px cover;
    background-position: 50% 0;

    padding-bottom: 150px;
  }
`;

const wrapperStyle = css`
  max-width: 620px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
`;

const Challenge: React.FC<Props> = ({ heading }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <TripleTitle
          labelText={heading.prefix}
          headingText={heading.heading}
          paragraphText={heading.shortText}
        />
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Challenge;
