import { graphql } from 'gatsby';
import React from 'react';
import { SEO } from '../components';
import { withLayout } from '../components/Layout';
import { Programs, TopSection } from '../components/Science';
import Certificates from '../components/Science/Certificates';
import Challenge from '../components/Science/Challenge';
import Download from '../components/Science/Download';
import EncyclopediaSection from '../components/Science/EncyclopediaSection';
import OutcomesSection from '../components/Science/OutcomesSection';
import Principles from '../components/Science/Principles';
import ReferencesSection from '../components/Science/ReferencesSection';
import ScientificBoard from '../components/Science/ScientificBoard';
import { ContentfulSciencePage } from '../graphql-types';

interface Props {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    science: ContentfulSciencePage;
  };
}

const Science: React.FC<Props> = ({
  pathContext: { locale },
  data: { science },
}) => {
  return (
    <>
      <SEO lang={locale} title={science.seoTitle} />
      <TopSection {...science.header} />
      <Challenge heading={science.challengeSection} />
      <Principles
        heading={science.carePrinciplesSection}
        mobileVine={science.carePrinciplesSectionMobileVine}
        desktopVine={science.carePrinciplesSectionDesktopVine}
        principlesParagraph={
          science.carePrinciplesSectionParagraph.childMarkdownRemark
        }
      />
      <OutcomesSection
        header={science.outcomesSectionHeader}
        outcomesBoxes={science.outcomesSectionBoxes}
        outcomesButton={science.outcomesSectionCta}
      />
      <ScientificBoard
        header={science.scientificBoardHeader}
        boardTestimonials={science.scientificBoardTestimonialList}
      />
      <Programs
        header={science.programsSectionHeader}
        boxes={science.programsSectionBoxes}
        finalStepText={science.programsSectionFinalStep}
      />
      <Download
        header={science.downloadSectionHeader}
        appStoreLink={science.downloadSectionAppStoreCta}
        playStoreLink={science.downloadSectionPlayStoreCta}
      />
      <Certificates
        header={science.certificatesSectionHeader}
        certificatesList={science.certificateSectionList}
      />
      <EncyclopediaSection
        header={science.encyclopediaSectionHeader}
        boxes={science.encyclopediaSectionBoxes}
      />
      <ReferencesSection block={science.referencesBlock} />
    </>
  );
};

export const sciencePageQuery = graphql`
  query sciencePage($locale: String!) {
    science: contentfulSciencePage(node_locale: { eq: $locale }) {
      seoTitle
      header {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      challengeSection {
        heading
        prefix
        shortText
      }
      carePrinciplesSection {
        prefix
        shortText
        heading
      }
      carePrinciplesSectionMobileVine {
        file {
          url
        }
        description
      }
      carePrinciplesSectionDesktopVine {
        description
        file {
          url
        }
      }
      carePrinciplesSectionParagraph {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      outcomesSectionHeader {
        shortText
        heading
        prefix
      }
      outcomesSectionBoxes {
        shortText
        heading
        image {
          file {
            url
          }
        }
      }
      outcomesSectionCta {
        url
        text
      }
      scientificBoardHeader {
        prefix
        heading
      }
      scientificBoardTestimonialList {
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      programsSectionHeader {
        heading
        prefix
        shortText
      }
      programsSectionBoxes {
        prefix
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      programsSectionFinalStep
      downloadSectionHeader {
        shortText
        prefix
        heading
      }
      downloadSectionAppStoreCta {
        url
        isDisplayed
      }
      downloadSectionPlayStoreCta {
        url
        isDisplayed
      }
      certificatesSectionHeader {
        prefix
        shortText
        heading
      }
      certificateSectionList {
        image {
          fixed(width: 200) {
            ...GatsbyContentfulFixed
          }
        }
        text {
          text
        }
      }
      encyclopediaSectionHeader {
        prefix
        heading
        shortText
      }
      encyclopediaSectionBoxes {
        heading
        shortText
        image {
          file {
            url
          }
        }
        link {
          url
        }
      }
      referencesBlock {
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        heading
      }
    }
  }
`;

export default withLayout(Science, { isNewLayout: true, isDarkHeader: false });
