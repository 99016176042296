import css from '@emotion/css';
import React from 'react';
import {
  ContentfulImageAndText,
  ContentfulImageHeadingAndShortText,
} from '../../graphql-types';
import { FullWidthSection } from '../common/layout';
import Testimonials from '../common/sections/Testimonials';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  boardTestimonials: ContentfulImageAndText[];
}

const sectionStyle = css`
  padding-top: 90px;
  padding-bottom: 90px;

  .slick-next:before,
  .slick-prev:before {
    content: '';
  }

  div:focus {
    outline: none;
  }
`;

const ScientificBoard: React.FC<Props> = ({ header, boardTestimonials }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <Testimonials header={header} testimonials={boardTestimonials} />
    </FullWidthSection>
  );
};

export default ScientificBoard;
