import css from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import {
  ContentfulImageHeadingAndLongText,
  ContentfulImageHeadingAndShortText,
} from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import MarkdownRenderer from '../common/MarkdownRenderer';
import {
  MonoFontLabel,
  RecoletaHeading,
  OpenSansParagraph,
} from '../common/typography';
import TripleTitle from '../common/TripleTitle';
import presets from '../../styles/presets';

import BackgroundVine from '../../assets/images/science/programs_vine.svg';
import AfterBubble from '../../assets/images/science/programs_after_bubble.svg';
import IconGreen from '../../assets/images/science/icon-green.svg';
import IconYellow from '../../assets/images/science/icon-yellow.svg';
import IconPink from '../../assets/images/science/icon-pink.svg';
import IconBlue from '../../assets/images/science/icon-blue.svg';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  boxes: ContentfulImageHeadingAndLongText[];
  finalStepText: string;
}

const sectionStyle = css`
  margin-top: 100px;
`;

const wrapperStyle = css`
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 500px;
`;

const BoxesContainer = styled.div`
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: url(${BackgroundVine});
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-clip: border-box;
  background-size: 194.2px 100%;

  ${presets.md} {
    background-position: 50% 0%;
  }

  ${presets.lg} {
    background-position: 40% 0%;
    background-size: 194.2px 100%;
  }
`;

const ItemBox = styled.div`
  padding: 20px;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #e2e8f7;
  border-radius: 8px;
  width: 100%;

  ${presets.md} {
    width: 560px;
    margin-bottom: 20px;
  }

  ${presets.lg} {
    margin-bottom: 30px;
    padding: 50px 60px;
  }
`;

const ItemLabel = styled(MonoFontLabel)`
  font-size: 10px;
  line-height: 19px;
  font-weight: 700;

  ${presets.md} {
    font-size: 12px;
  }
`;

const ItemHeading = styled(RecoletaHeading)`
  font-size: 16px;
  line-height: 26px;

  ${presets.md} {
    font-size: 20px;
    line-height: 30px;
  }

  ${presets.lg} {
    font-size: 24px;
    line-height: 34px;
  }
`;

const ItemParagraph = styled(OpenSansParagraph)`
  font-size: 12px;
  line-height: 18px;
  opacity: 80%;
  font-weight: 400;
  margin-top: 12px;

  ul {
    list-style-type: none;
    margin: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      border: 0.5px solid #69b0ae;
      border-radius: 5px;
      width: fit-content;

      span {
        width: 26px;
        height: 26px;
        display: inline-block;
        margin-right: 5px;

        &.icon-green {
          background: url(${IconGreen});
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.icon-yellow {
          background: url(${IconYellow});
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.icon-pink {
          background: url(${IconPink});
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.icon-blue {
          background: url(${IconBlue});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }

  ${presets.md} {
    font-size: 14px;
    line-height: 20px;
  }

  ${presets.lg} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const BubbleContainer = styled.div`
  background: url(${AfterBubble});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  margin: -15px auto 0 auto;
  font-size: 23px;
  padding: 30px 50px 15px 30px;
  width: fit-content;

  ${presets.md} {
    font-size: 28px;
    padding-right: 60px;
  }
`;

const Programs: React.FC<Props> = ({ header, boxes, finalStepText }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <HeaderContainer>
          <TripleTitle
            labelText={header.prefix}
            headingText={header.heading}
            paragraphText={header.shortText}
          />
        </HeaderContainer>
        <BoxesContainer>
          {boxes.map((item, ind) => (
            <ItemBox key={ind}>
              <ItemLabel>{item.prefix}</ItemLabel>
              <ItemHeading>{item.heading}</ItemHeading>
              <ItemParagraph>
                <MarkdownRenderer {...item.text.childMarkdownRemark} />
              </ItemParagraph>
            </ItemBox>
          ))}
        </BoxesContainer>
        <BubbleContainer>
          <RecoletaHeading>{finalStepText}</RecoletaHeading>
        </BubbleContainer>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Programs;
