import css from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import { ContentfulImageHeadingAndShortText } from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import TripleTitle from '../common/TripleTitle';
import presets from '../../styles/presets';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  boxes: ContentfulImageHeadingAndShortText[];
}

const sectionStyle = css`
  margin-top: 200px;
`;

const wrapperStyle = css`
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const BoxesContainer = styled.div`
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  ${presets.md} {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  ${presets.lg} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

const EncyclopediaBox = styled.a(
  ({ color }) => css`
    &:hover {
      text-decoration: none;
    }
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${color};

    ${presets.md} {
      width: 30%;
      margin-left: 10px;
      margin-right: 10px;
    }

    ${presets.lg} {
      width: 18%;
    }
  `
);

const boxHeadingStyle = css`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 3px;

  ${presets.lg} {
    font-size: 18px;
    line-height: 30px;
  }
`;

const boxParagraphStyle = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;

  ${presets.lg} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Image = styled.img`
  height: 55px;
`;

const EncyclopediaSection: React.FC<Props> = ({ header, boxes }) => {
  const colors = ['#E2E8F7', '#F0E9F3', '#FEF7E7', '#FDF5F6', '#E4EFEE'];

  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <HeaderContainer>
          <TripleTitle
            labelText={header.prefix}
            headingText={header.heading}
            paragraphText={header.shortText}
          />
        </HeaderContainer>
        <BoxesContainer>
          {boxes.map((item, ind) => (
            <EncyclopediaBox
              color={colors[ind]}
              key={ind}
              href={item.link ? item.link.url : '#'}
            >
              <Image src={item.image.file.url} alt="" />
              <RecoletaHeading css={boxHeadingStyle}>
                {item.heading}
              </RecoletaHeading>
              <OpenSansParagraph css={boxParagraphStyle}>
                {item.shortText}
              </OpenSansParagraph>
            </EncyclopediaBox>
          ))}
        </BoxesContainer>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default EncyclopediaSection;
