import css from '@emotion/css';
import React from 'react';
import { ContentfulHeadingAndText } from '../../graphql-types';
import { FullWidthSection } from '../common/layout';
import References from '../common/sections/References';

interface Props {
  block: ContentfulHeadingAndText;
}

const sectionStyle = css`
  margin-top: 100px;
  margin-bottom: 100px;
`;

const ReferencesSection: React.FC<Props> = ({ block }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <References block={block} collapsedMessage="" />
    </FullWidthSection>
  );
};

export default ReferencesSection;
