import { css } from '@emotion/core';
import React from 'react';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
} from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import BackgroundImageMobile from '../../assets/images/science/download_background_mobile.svg';
import BackgroundImageDesktop from '../../assets/images/science/download_background_desktop.svg';
import presets from '../../styles/presets';
import styled from '@emotion/styled';
import AppStoreButton from '../common/appbuttons/AppStoreButton';
import GooglePlayStoreButton from '../common/appbuttons/GooglePlayStoreButton';
import { ButtonLink } from '../common/Button';
import { useIntl } from 'react-intl';
import {
  eligibleTitle,
  typeformEligiblityCheckerLink,
} from '../../utils/constants';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  appStoreLink: ContentfulLink;
  playStoreLink: ContentfulLink;
}

const sectionStyle = css`
  margin-top: 100px;

  background: url(${BackgroundImageMobile});
  background-repeat: no-repeat;
  background-position: 55% 0;

  padding-top: 106px;
  padding-bottom: 106px;

  ${presets.md} {
    background: url(${BackgroundImageDesktop});
    background-repeat: no-repeat;
    background-position: 30% 0;
  }

  ${presets.xl} {
    background-position: 50% 0;
  }
`;

const wrapperStyle = css`
  margin: 0 auto;

  div div {
    color: white;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  ${presets.md} {
    flex-direction: row;
    margin: 40px auto;
    justify-content: center;
  }
`;

const Download: React.FC<Props> = ({ header, appStoreLink, playStoreLink }) => {
  const intl = useIntl();

  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <TripleTitle
          labelText={header.prefix}
          headingText={header.heading}
          paragraphText={header.shortText}
        />
      </ContentWrapper>
      <ButtonContainer>
        {intl.locale === 'de' ? (
          <ButtonLink to={typeformEligiblityCheckerLink}>
            {eligibleTitle}
          </ButtonLink>
        ) : (
          <>
            {appStoreLink && appStoreLink.isDisplayed && (
              <AppStoreButton href={appStoreLink.url} />
            )}
            {playStoreLink && playStoreLink.isDisplayed && (
              <GooglePlayStoreButton href={playStoreLink.url} />
            )}
          </>
        )}
      </ButtonContainer>
    </FullWidthSection>
  );
};

export default Download;
