import css from '@emotion/css';
import React from 'react';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
} from '../../graphql-types';
import { FullWidthSection } from '../common/layout';
import Outcomes from '../common/sections/Outcomes';
import BackgroundImage from '../../assets/images/science/outcomes_background.svg';
import presets from '../../styles/presets';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  outcomesBoxes: ContentfulImageHeadingAndShortText[];
  outcomesButton: ContentfulLink;
}

const sectionStyle = css`
  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  padding-top: 90px;
  padding-bottom: 100px;

  ${presets.md} {
    margin-top: 100px;
  }

  ${presets.xl} {
    background-size: 130% 100%;
  }
`;

const OutcomesSection: React.FC<Props> = ({
  header,
  outcomesBoxes,
  outcomesButton,
}) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <Outcomes
        header={header}
        outcomesBoxes={outcomesBoxes}
        outcomesButton={outcomesButton}
      />
    </FullWidthSection>
  );
};

export default OutcomesSection;
