import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
} from '../../../graphql-types';
import { ContentWrapper } from '../layout';
import { OpenSansParagraph, RecoletaHeading } from '../typography';
import TripleTitle from '../TripleTitle';
import presets from '../../../styles/presets';
import { ButtonLink } from '../Button';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  outcomesBoxes: ContentfulImageHeadingAndShortText[];
  outcomesButton: ContentfulLink;
}

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const HeadingContainer = styled.div(css`
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;

  ${presets.md} {
    margin-bottom: 42px;
  }
`);

const BoxesContainer = styled.div(css`
  margin-left: 10px;
  margin-right: 10px;
`);

const OutcomesBox = styled.div(css`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #d7e8e7;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  padding: 30px;
  text-align: center;

  ${presets.md} {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    width: 700px;
    height: 120px;
    margin-top: 16px;
    padding: 0 20px;
  }
`);

const outcomeHeadingStyle = css`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 18px;

  #percent {
    font-size: 24px;
  }

  ${presets.md} {
    width: 25%;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 0;

    #percent {
      font-size: 28px;
    }
  }

  ${presets.lg} {
    font-size: 44px;
    line-height: 58px;

    #percent {
      font-size: 32px;
    }
  }

  ${presets.xl} {
    font-size: 48px;
    line-height: 64px;

    #percent {
      font-size: 36px;
    }
  }
`;

const outcomeParagraphStyle = css`
  font-size: 16px;
  line-height: 24px;

  ${presets.md} {
    width: 50%;
    font-size: 18px;
    line-height: 26px;
  }
`;

const Outcomes: React.FC<Props> = ({
  header,
  outcomesBoxes,
  outcomesButton,
}) => {
  return (
    <ContentWrapper css={wrapperStyle}>
      <HeadingContainer>
        <TripleTitle
          headingText={header.heading}
          labelText={header.prefix}
          paragraphText={header.shortText}
        />
      </HeadingContainer>
      <BoxesContainer>
        {outcomesBoxes.map((ob, ind) => (
          <OutcomesBox key={ind}>
            <img
              src={ob.image.file.url}
              alt={ob.image.title}
              css={css`
                ${presets.md} {
                  margin-top: auto;
                  margin-bottom: auto;
                }
              `}
            />
            <RecoletaHeading css={outcomeHeadingStyle}>
              {ob.heading}
              <span id="percent">%</span>
            </RecoletaHeading>
            <OpenSansParagraph css={outcomeParagraphStyle}>
              {ob.shortText}
            </OpenSansParagraph>
          </OutcomesBox>
        ))}
      </BoxesContainer>
      <ButtonLink
        to={outcomesButton.url}
        css={css`
          margin-top: 20px;
          width: 300px;
        `}
      >
        {outcomesButton.text}
      </ButtonLink>
    </ContentWrapper>
  );
};

export default Outcomes;
