import css from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import {
  ContentfulImageAndText,
  ContentfulImageHeadingAndShortText,
} from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import presets from '../../styles/presets';
import { OpenSansParagraph } from '../common/typography';
import Img from 'gatsby-image/withIEPolyfill';
interface Props {
  header: ContentfulImageHeadingAndShortText;
  certificatesList: ContentfulImageAndText[];
}

const sectionStyle = css`
  margin-top: 110px;
`;

const wrapperStyle = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
`;

const HeadingWrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
`;

const CertificatesWrapper = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 60px;
  column-gap: 10px;
  row-gap: 10px;

  ${presets.md} {
    margin-top: 80px;
    row-gap: 60px;
  }

  ${presets.lg} {
    grid-template-columns: 200px 200px 200px 200px;
    justify-content: center;
    column-gap: 50px;
  }

  ${presets.xl} {
    grid-template-columns: 300px 300px 300px 300px;
    column-gap: normal;
  }
`;

const ListItem = styled.div`
  place-self: center stretch;
  max-width: 100%;

  ${presets.md} {
    place-self: center;
  }
`;

const Text = styled(OpenSansParagraph)`
  opacity: 100;
  font-size: 11px;
  font-weight: 400;
  max-width: 110px;

  ${presets.md} {
    font-size: 20px;
    line-height: 26px;
    max-width: 200px;
  }
`;

const Image = styled(Img)`
  max-width: 100%;
  width: 100%;
  margin-bottom: 0px;
`;

const Certificates: React.FC<Props> = ({ header, certificatesList }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <HeadingWrapper>
          <TripleTitle
            labelText={header.prefix}
            headingText={header.heading}
            paragraphText={header.shortText}
          />
        </HeadingWrapper>
        <CertificatesWrapper>
          {certificatesList.map((item, ind) => (
            <ListItem key={ind}>
              {item.image && (
                <Image
                  fixed={item.image.fixed}
                  objectFit="scale-down"
                  objectPosition="center"
                />
              )}
              <Text>{item.text.text}</Text>
            </ListItem>
          ))}
        </CertificatesWrapper>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Certificates;
