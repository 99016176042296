import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulImageAndText,
} from '../../../graphql-types';
import { ContentWrapper } from '../layout';
import MarkdownRenderer from '../MarkdownRenderer';
import TripleTitle from '../TripleTitle';
import Img from 'gatsby-image';
import presets from '../../../styles/presets';
import { OpenSansParagraph, MonoFontLabel } from '../typography';

import theme from '../../../styles/theme';
import SliderArrow from '../slider/SliderArrow';
interface Props {
  header: ContentfulImageHeadingAndShortText;
  testimonials: ContentfulImageAndText[];
}

const contentWrapperStyle = css`
  ${presets.lg} {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const sliderWrapperStyle = css`
  padding-left: 25px;
  padding-right: 25px;
`;

const TestimonialSection = styled.div(css`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;

  ${presets.md} {
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }
`);

const TestimonialText = styled(OpenSansParagraph)(css`
  opacity: 100%;
  margin-top: 30px;

  h3 {
    ${MonoFontLabel.__emotion_styles};
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
  }

  small {
    font-size: 10px;
    opacity: 0.9;
  }

  h3 + p {
    max-width: 400px;
    line-height: 16px;
  }

  ${presets.md} {
    flex-basis: 45%;
    flex-grow: 1;
    padding-left: 50px;

    font-size: 20px;

    h3 {
      font-size: 16px;
    }

    h3 + p {
      line-height: 18px;
    }

    small {
      font-size: 14px;
    }
  }
`);
const TestimonialImage = styled.div(css`
  ${presets.md} {
    flex-basis: 45%;
    flex-grow: 1;
    padding-right: 50px;
  }
`);

const MobileArrows = styled.div(css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  align-self: flex-start;
  margin-left: 20px;

  ${presets.md} {
    display: none;
  }
`);

const testimonialsSliderSettings: Settings = {
  dots: false,
  centerMode: true,
  centerPadding: '0px',
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SliderArrow position="right" />,
  prevArrow: <SliderArrow position="left" />,
  focusOnSelect: false,
  swipeToSlide: true,
  draggable: false,
  responsive: [
    {
      breakpoint: theme.breakpoints.md,
      settings: {
        arrows: false,
        nextArrow: null,
        prevArrow: null,
      },
    },
  ],
};

const Testimonials: React.FC<Props> = ({ header, testimonials }) => {
  const ref = useRef<Slider>(null);
  return (
    <ContentWrapper css={contentWrapperStyle}>
      <TripleTitle
        headingText={header.heading}
        paragraphText={header.shortText}
        labelText={header.prefix}
      />
      <Slider {...testimonialsSliderSettings} ref={ref}>
        {testimonials.map((t) => (
          <div key={t.text.childMarkdownRemark.id} css={sliderWrapperStyle}>
            <TestimonialSection>
              <TestimonialText>
                <MarkdownRenderer {...t.text.childMarkdownRemark} />
              </TestimonialText>
              <TestimonialImage>
                <Img
                  fluid={t.image.fluid}
                  css={css`
                    object-fit: scale-down;
                    max-width: 400px;
                    object-position: 50% 50%;
                    margin: 0 auto;
                  `}
                />
              </TestimonialImage>
            </TestimonialSection>
          </div>
        ))}
      </Slider>
      <MobileArrows>
        <SliderArrow position="left" onClick={() => ref.current.slickPrev()} />
        <SliderArrow position="right" onClick={() => ref.current.slickNext()} />
      </MobileArrows>
    </ContentWrapper>
  );
};

export default Testimonials;
