import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { ContentfulSciencePageHeaderTextNode } from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import MobileImage from '../../assets/images/science/header_illustartion_mobile.svg';
import hi1 from '../../assets/images/science/header_illustraton_1.svg';
import hi2 from '../../assets/images/science/header_illustraton_2.svg';
import hi3 from '../../assets/images/science/header_illustraton_3.svg';
import hi4 from '../../assets/images/science/header_illustraton_4.svg';
import hi5 from '../../assets/images/science/header_illustraton_5.svg';
import hi6 from '../../assets/images/science/header_illustraton_6.svg';
import presets from '../../styles/presets';

const wrapperStyle = css`
  padding-top: 30px;
  padding-bottom: 30px;

  ${presets.md} {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  ${presets.lg} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const MobileImageHolder = styled.img`
  width: 100%;
  ${presets.lg} {
    display: none;
  }
`;

const ImageHolder = styled.div`
  display: none;

  ${presets.lg} {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-grow: 1;
  }
`;

const HeaderImage = styled.img`
  width: 80px;
  height: 80px;
`;

const Text = styled.div`
  text-align: center;
  width: 280px;
  margin: 0 auto;

  h1 {
    ${RecoletaHeading.__emotion_styles}
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 17px;

    strong {
      font-style: normal;
      font-weight: 600;
    }
  }

  p {
    ${OpenSansParagraph.__emotion_styles}
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    opacity: 80px;
  }

  ${presets.md} {
    width: 470px;

    h1 {
      font-size: 44px;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  ${presets.lg} {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const TopSection: React.FC<ContentfulSciencePageHeaderTextNode> = (text) => {
  return (
    <FullWidthSection>
      <ContentWrapper css={wrapperStyle}>
        <ImageHolder>
          <HeaderImage src={hi1} />
          <HeaderImage src={hi2} />
          <HeaderImage src={hi3} />
        </ImageHolder>
        <Text>
          <MarkdownRenderer {...text.childMarkdownRemark} />
        </Text>
        <MobileImageHolder src={MobileImage}></MobileImageHolder>
        <ImageHolder>
          <HeaderImage src={hi4} />
          <HeaderImage src={hi5} />
          <HeaderImage src={hi6} />
        </ImageHolder>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default TopSection;
